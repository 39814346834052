.logo-bg img {
  position: absolute;
  z-index: 0;
}

.logo h1 {
  position: relative;
  left: 2rem;
  color: green;
  font-weight: 600;
  font-size: 1.8em;
}

.promo-img img {
  position: relative;
  display: flex;
  justify-content: center;
  width: auto;
  height: 65vmin;
  //margin-top: 0;
  margin: auto;
  //margin-left: 60px;

}

.promo-text {
  .get-deal {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 2.1rem;
    //margin-left: 6rem;
    color: #ff1744;
    margin-top: 1rem;
    font-weight: 600;
    margin-bottom: .2em;
  }

  .auto-redir {
    font-size: .8em;
    color: #576574;
  }
}

.bg-dots {
  position: absolute;
  background-image: url("../../images/dots.png");
  right: -15rem;
  top: 20rem;
}

.arrow {
  position: absolute;
  right: 0;
  top: 25rem;
}

.video {
  width: 80% !important;
  margin-left: 10%;
  margin-top: 4.2rem;
}

@media only screen and (max-width: 576px) {
  .promo-img img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
  .promo-text p {
    font-size: 1.5rem;
    color: #ff1744;
    margin-top: 1rem;
    margin-left: 0;
    justify-content: center;
  }
  .logo h1 {
    left: 0;
    display: flex;
    justify-content: center;
  }
  .video {
    width: 90% !important;
    margin-left: 5%;
    margin-top: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .promo-img img {
    width: 100%;
    height: auto;
    margin-left: 0;
  }

  .promo-text {
    .get-deal {
      font-size: 1.5rem;
      color: #ff1744;
      margin-top: 1rem;
      margin-left: 0;
      justify-content: center;
    }

  }
  .logo h1 {
    left: 0;
    display: flex;
    justify-content: center;
  }
}