.upload-countdown {
  color: #ff1744;
  font-weight: 600;

  i {
    vertical-align: middle;
    margin-right: 5px;
    font-weight: 600;
    font-size: 1.2em;
  }

  .end-in {
    //min-width: 150px;
    text-align: right;
    margin-bottom: 0;
    font-size: 1.3em;
  }
}