.menu-logo{
  width: 120px;
  height: auto;
}

.unsub-nav{
  border-bottom: 2px solid #ced6e0;
  box-shadow: 1px 2px 2px #718093;
}

.optout-form{
  padding: 20px 25px;
  margin-top: 2em;
  background-color: #dcdde1;
  border-radius: 10px;
  min-height: 15em;
  box-shadow: 3px 2px 3px #b2bec3;
}

.unsub-description, .text-small{
  font-size: .8em;
}

.keep-in{
  color: #3867d6;
  margin-top: .5em;
  display: block;
  text-decoration: underline;
}