// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
body {
  font-size: 1rem !important;
}

h1 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}