// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.top-header-main-bg {
  //background-color: var(--form-label);
  background-color: #0c2461;
  width: 100%;
  height: 45px;
  margin-bottom: 5px;
}

.top-header-secondary-bg {
  width: 100%;
  height: 45px;
  background: rgb(235,235,235);
  background: -moz-linear-gradient(180deg, rgba(235,235,235,1) 50%, rgba(247,247,247,1) 50%);
  background: -webkit-linear-gradient(180deg, rgba(235,235,235,1) 50%, rgba(247,247,247,1) 50%);
  background: linear-gradient(180deg, rgba(235,235,235,1) 50%, rgba(247,247,247,1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb",endColorstr="#f7f7f7",GradientType=1);
}

.top-header-arrow-bg {
  position: absolute;
  border-style: solid;
  //border-width: 22.5px 0 22.5px 20px;
  border-width: 23px 0 23px 20px;
  top: -1px;
  //border-color: transparent transparent transparent var(--form-label);
  border-color: transparent transparent transparent #0c2461;
  z-index: 1;
  right: -1.23rem;
}

.header-text h1 {
  position: relative;
  display: flex;
  justify-content: left;
  font-size: 1rem;
  margin-left: 1.5rem;
  padding-top: 0.8rem;
  color: var(--topheader-color);
}

.header-sub-text h1 {
  position: absolute;
  top: 0;
  font-size: 1rem;
  padding-top: 0.8rem;
  color: var(--topheader-sub-color);
  margin-left: 2rem;
  z-index: 2;
}

@media only screen and (max-width: 576px) {
  .top-header-main-bg {
    //display: none;
    h1{
      font-size: 1.2em;
    }
  }
  .header-text {
    font-size: 0.5rem;
  }
  .header-sub-text {
    display: none;
  }
  .top-header-arrow-bg {
    display: none;
  }
}