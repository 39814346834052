.form-bg {
  position: relative;
  width: 95%;
  height: auto;
  border-radius: 10px;
  -webkit-box-shadow: 0 7px 17px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 7px 17px -1px rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 1rem;
  min-height: 80vh;
  //padding: 3rem 3rem 3rem 3rem
}

.form-header {
  width: 100%;
  height: auto;
  //background-color: #070761;
  background-color: #00b894;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 2px 2px #596275;

  h1 {
    color: var(--form-header);
    text-align: center;
    font-weight: bold;
    padding: 1rem;
    font-size: 1.5em;
  }
}

.form-group {
  margin-bottom: 0 !important;
}

.form-label10 {
  .form-label {
    display: inline-block;
    margin-bottom: .3rem;
    font-size: 1rem;
    color: var(--form-label);
    //margin-left: 4rem;
    //margin-top: 1rem;
    margin-top: 4%;
  }
}

.form-input {
  .form-control {
    width: 100%;
    height: 3rem;
    border: 3px solid #E0E0E0;
    font-size: 1.1rem;
  }
}

.footer-text p {
  font-size: 0.8rem;
  margin-top: 1.3rem;
}

.privacy {
  font-size: 0.8em;
}

.send-now-btn {
  .btn-primary {
    position: relative;
    width: 100%;
    height: auto;
    font-size: 1.8rem;
    font-weight: 600;
    color: #ffffff;
    background-color: var(--btn-bg);
    border-color: var(--primary-color);
    border-radius: 5px;
  }

  .btn-primary:hover {
    color: #ffffff;
    background-color: var(--btn-bg);
    border-color: var(--btn-bg);
  }

  .btn-primary:focus {
    color: #ffffff;
    background-color: var(--btn-bg);
    border-color: var(--primary-color);
  }
}

.feedback {
  margin-top: 10%;
  font-size: 1.5em;
}

.feedback-important {
  font-style: italic;
  color: #0c2461;

  .highlight {
    font-weight: bold;
    text-decoration: underline;
  }
}

.view-store {
  font-size: 1.5em;
  text-decoration: underline;
  color: #2e86de;
}

.view-store-only {
  font-size: .9em;
  text-decoration: underline;
  display: block;
  color: #74b9ff;
  //color: #c8d6e5;
  margin-top: 1.2em;
}

.view-store-only:hover {
  color: #f1f1f1;
}


@media only screen and (max-width: 576px) {
  .form-bg {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .form-header h1 {
    padding: 0.5rem;
    font-size: .9em;
  }
  .privacy {
    font-size: 0.7em !important;
  }
  .send-now-btn {
    .btn-primary {
      font-size: .95em;
    }
  }
  .view-store-only {
    margin-bottom: 2em;
    color: #636e72;
  }

}