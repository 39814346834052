.homepage-bg {
  //background-position: 0 0;
  height: calc(100vh - 50px);
  //display: flex;
  background-repeat: no-repeat;

  background: rgb(21,101,192);
  background: -moz-linear-gradient(248deg, rgba(21,101,192,1) 50%, rgba(255,255,255,1) 50%);
  background: -webkit-linear-gradient(248deg, rgba(21,101,192,1) 50%, rgba(255,255,255,1) 50%);
  background: linear-gradient(248deg, rgba(21,101,192,1) 50%, rgba(255,255,255,1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1565c0",endColorstr="#ffffff",GradientType=1);
}

.countdown-container{
  right: 0;
}

@media only screen and (max-width: 576px) {
  .homepage-bg {
    background: #ffffff;
  }
}